import { API, logError } from "./index";

const getEvents = async (page, filters) => {
    let eventsURL = `/events?page=${page}`;

    if (filters?.partner_id) eventsURL = `${eventsURL}&partner_id=${filters.partner_id}`;

    if (filters?.speciality_id) eventsURL = `${eventsURL}&speciality_id=${filters.speciality_id}`;

    if (filters?.time) eventsURL = `${eventsURL}&time=${filters.time}`;

    try {
        return await API().get(eventsURL);
    } catch (error) {
        return logError(error);
    }
};

const getSingleEvent = (id) => {
    try {
        return API().get(`/event?event_id=${id}`);
    } catch (error) {
        return logError(error);
    }
};

const getRelatededEvent = (eventID) => {
    try {
        return API().get(`/suggest-event?event_id=${eventID}`);
    } catch (error) {
        return logError(error);
    }
};

const getSuggestedEvent = () => {
    try {
        return API().get(`/one-Event`);
    } catch (error) {
        return logError(error);
    }
};


export {
    getEvents,
    getSingleEvent,
    getRelatededEvent,
    getSuggestedEvent
};